<template>
    <div>
        <div class="classMode">
            <div class="classMode-box">
                <div class="classMode-box-nav">
                    <div>教室</div>
                    <div></div>
                    <div>周一 ({{moment(week[0]).format('MM-DD')}})</div>
                    <div>周二 ({{moment(week[1]).format('MM-DD')}})</div>
                    <div>周三 ({{moment(week[2]).format('MM-DD')}})</div>
                    <div>周四 ({{moment(week[3]).format('MM-DD')}})</div>
                    <div>周五 ({{moment(week[4]).format('MM-DD')}})</div>
                    <div>周六 ({{moment(week[5]).format('MM-DD')}})</div>
                    <div>周日 ({{moment(week[6]).format('MM-DD')}})</div>
                </div>
                <div v-for="(room,key,index) in list" :key="index" class="classMode-box-item">
                    <div class="classMode-box-item-left">
                        {{key}}{{JSON.stringify(room['上午'].long)}}
                    </div>
                    <div class="classMode-box-item-right">
                        <div style="background:#e0e0f6" class="classMode-box-item-right-box">
                            <div class="classMode-box-item-right-boxL">
                                上午
                            </div>
                            <div class="classMode-box-item-right-boxR">
                                <div v-for="(item,i) in room['上午'].long" :key="i" class="classMode-box-item-right-boxR-time">
                                    <div v-for="(it,j) in week" :key="j">{{room['上午'][it].length>0?room['上午'][it][i].class_time:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="background:#b5c5fa" class="classMode-box-item-right-box">
                            <div class="classMode-box-item-right-boxL">
                                下午
                            </div>
                            <div class="classMode-box-item-right-boxR">
                                <div v-for="(item,i) in room['下午'].long" :key="i" class="classMode-box-item-right-boxR-time">
                                    <div v-for="(it,j) in week" :key="j">{{room['下午'][it].length>0?room['下午'][it][i].class_time:'-'}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="background:#7987c0" class="classMode-box-item-right-box">
                            <div class="classMode-box-item-right-boxL">
                                晚上
                            </div>
                            <div class="classMode-box-item-right-boxR">
                                <div v-for="(item,i) in room['晚上'].long" :key="i" class="classMode-box-item-right-boxR-time">
                                    <div v-for="(it,j) in week" :key="j">{{room['晚上'][it].length>0?room['晚上'][it][i].class_time:'-'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name:'classMode',
        data() {
            return {
                list: {},
                week:[]
            }
        },
        created () {
            this.getList()
            this.week = this.weDateFormat('2020-10-01')
        },
        methods: {
            moment,
            async getList() {
                let res = await this.$store.dispatch('courseRoomAction', {})
                this.toList(res.data)
            },
            weDateFormat(date, strDate) {
                let weekOfDay = moment(date).format("E") // 指定日期的周的第几天
                let lastMonday = moment(date).subtract(weekOfDay-1, 'days').format('YYYY-MM-DD');//周一日期
                let lastTuesday = moment(date).subtract(weekOfDay-2, 'days').format('YYYY-MM-DD');//周二日期
                let lastWednesday = moment(date).subtract(weekOfDay-3, 'days').format('YYYY-MM-DD');//周三日期
                let lastThursday = moment(date).subtract(weekOfDay-4, 'days').format('YYYY-MM-DD');//周四日期
                let lastFriday = moment(date).subtract(weekOfDay-5, 'days').format('YYYY-MM-DD');//周五日期
                let lastSaturday = moment(date).subtract(weekOfDay-6, 'days').format('YYYY-MM-DD');//周六日期
                let lastSunday = moment(date).add(7-weekOfDay, 'days').format('YYYY-MM-DD');//周日日期
                return [lastMonday, lastTuesday, lastWednesday, lastThursday, lastFriday, lastSaturday, lastSunday]
            },
            toList(obj){
                for(let i in obj){
                    obj[i]['上午'] = {
                        [this.week[0]]:[],
                        [this.week[1]]:[],
                        [this.week[2]]:[],
                        [this.week[3]]:[],
                        [this.week[4]]:[],
                        [this.week[5]]:[],
                        [this.week[6]]:[],
                        long:1
                    }
                    obj[i]['下午'] = {
                        [this.week[0]]:[],
                        [this.week[1]]:[],
                        [this.week[2]]:[],
                        [this.week[3]]:[],
                        [this.week[4]]:[],
                        [this.week[5]]:[],
                        [this.week[6]]:[],
                        long:1
                    }
                    obj[i]['晚上'] = {
                        [this.week[0]]:[],
                        [this.week[1]]:[],
                        [this.week[2]]:[],
                        [this.week[3]]:[],
                        [this.week[4]]:[],
                        [this.week[5]]:[],
                        [this.week[6]]:[],
                        long:1
                    }
                    for(let j in obj[i]){
                        if(obj[i][j].length){
                            obj[i][j].forEach(item=>{
                                let name = moment(item.class_start).format('a')
                                let time = moment(item.class_start).format('YYYY-MM-DD')
                                if(obj[i][name][time]){
                                    obj[i][name][time].push(item)
                                }
                                
                            })
                        }
                    }
                }
                for(let i in obj){
                    for(let j in obj['上午']){
                        if(obj[i]['上午'][j].length > obj[i]['上午'].long){
                            obj[i]['上午'].long = obj[i]['上午'][j].length 
                        }
                    }
                    for(let j in obj['下午']){
                        if(obj[i]['下午'][j].length > obj[i]['下午'].long){
                            obj[i]['下午'].long = obj[i]['下午'][j].length 
                        }
                    }
                    for(let j in obj['晚上']){
                        if(obj[i]['晚上'][j].length > obj[i]['晚上'].long){
                            obj[i]['晚上'].long = obj[i]['晚上'][j].length 
                        }
                    }
                }
                this.list = obj
            }
        },
    }
</script>

<style lang="scss" scoped>
    .classMode{
        display: inline-block;
    }
    .classMode-box{
        border: 1px solid #e8e8e8;
        &-nav{
            display: flex;
            div{
                width: 130px;
                height: 50px;
                text-align: center;
                line-height: 50px;
            }
            div:nth-child(1){
                border-right: 1px solid #e8e8e8;
            }
            div:nth-child(2){
                width: 70px;
            }

        }
        &-item{
            display: flex;
            &-left{
                width: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid #e8e8e8;
            }
            &-right{
                &-box{
                    display: flex;
                }
                &-boxL{
                    width: 70px;
                    border-bottom:1px solid #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &-boxR{
                    &-time{
                        display: flex;
                        border-bottom:1px solid #fff;
                        div{
                            width: 130px;
                            height: 50px;
                            text-align: center;
                            line-height: 50px;
                            border-left:1px solid #fff;
                        }
                    }
                }
            }
        }
    }
</style>