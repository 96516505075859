<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>

                <!-- <a-button type="primary" icon="plus" @click="showEditModal()">新增排课</a-button> -->

                <a-button type="dashed" icon="plus" @click="showPkModal">快捷排课</a-button>

                <a-button style="margin-left:10px" icon="export" @click="toExport" :loading="exportLoading">导出</a-button>

            </a-form-item>
          </a-form>
      </div>
      <div class="search">
          <a-form v-if="selectedRowKeys.length == 0" layout='inline'>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-radio-group :value="searchParams.search.is_summary">
                <a-radio-button @click="handleTypeButChange('0','is_summary')" value="0">
                    未备课
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('1','is_summary')" value="1">
                    已备课
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <a-radio-group :value="searchParams.search.is_demo">
                <a-radio-button @click="handleTypeButChange('0','is_demo')" value="0">
                    常规课
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('1','is_demo')" value="1">
                    体验课
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker
                    style="width: 240px"
                    :ranges="rangesData"
                    :value='rangesValue'
                    :allowClear="false"
                    :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]" 
                    @change="(date, dateString)=>onChange(date, dateString,2)" />
                </a-tooltip>
            </a-form-item>

            <a-form-item>
              <a-tooltip placement="topLeft" >
                  <template slot="title">
                      <span>所属校区</span>
                  </template>
                  <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption" style="width: 220px;">
                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                  </a-select>
              </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>班级名称</span>
                </template>
                <a-input allowClear v-model.trim="searchParams.search.class_name" placeholder="班级名称" style="width: 160px"/>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchParams.search.course_cate_id" placeholder="请选择课程科目" showSearch @search="e=>handleSearch(e,'getClassCategory')" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item v-if="searchParams.search.is_sub == 1" class="block-line" label="授课老师" >
                        <a-select allowClear v-model="searchParams.search.teacher_id" showSearch @search="e=>handleSearch(e,'getTeacher')" :filterOption="filterOption" placeholder="请选择">
                          <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item v-if="searchParams.search.is_sub == 1" class="block-line" label="助教老师" >
                        <a-select allowClear v-model="searchParams.search.teacher_assistant_ids" showSearch @search="e=>handleSearch(e,'getTeacher')" :filterOption="filterOption" placeholder="请选择">
                          <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="课程模式" >
                        <a-select allowClear v-model="searchParams.search.class_mode" placeholder="请选择">
                          <a-select-option v-for="(d, index) of classModes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="上课教室" >
                        <a-select v-model="searchParams.search.room_id" showSearch @search="e=>handleSearch(e,'getRoom')" allowClear placeholder="请选择上课教室" :filterOption="filterOption">
                            <a-select-option v-for="(item, index) of rooms" :key="index" :value="item.room_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="排课学员">
                            <a-input-group compact>
                                <a-select style="width:100px" v-model="book_optionValue" @change="onChangeBook">
                                    <a-select-option v-for="(item,index) in book_optionList" :key="index" :value="item.value">{{item.label}}</a-select-option>
                                </a-select>
                                <a-input-number style="width:140px" v-model="book_number" :min="0" @change="onChangeBook" />
                            </a-input-group>
                      </a-form-item>
                      <a-form-item class="block-line" label="到课学员">
                            <a-input-group compact>
                                <a-select style="width:100px" v-model="customer_optionValue" @change="onChangeCostWarn">
                                    <a-select-option v-for="(item,index) in customer_optionList" :key="index" :value="item.value">{{item.label}}</a-select-option>
                                </a-select>
                                <a-input-number style="width:140px" v-model="customer_number" :min="0" @change="onChangeCostWarn" />
                            </a-input-group>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
          <a-form layout='inline' v-else>
              <a-form-item>
                  已选择<span>{{selectedRowKeys.length}}</span>项
              </a-form-item>
              <a-form-item>
                  <a-divider type="vertical" />
              </a-form-item>
              <a-form-item>
                  <a-button @click="showAdjustModal">批量调课</a-button>
              </a-form-item>
              <a-form-item>
                  <a-button @click="showTimeModal">批量顺延</a-button>
              </a-form-item>
              <a-form-item>
                  <a-button @click="toCancel">取消排课</a-button>
              </a-form-item>
              <a-form-item>
                  <a-button @click="cleanCourse()">批量撤销签到</a-button>
              </a-form-item>
              <a-form-item v-if="appid === 'mMKcL4'">
                  <a-button :loading="syncLoading" @click="toSync">慧动按钮</a-button>
              </a-form-item>
          </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="course_class_id"
        :selection="{key: 'course_class_id'}" @change="handleChange"
        :rowClassName="record=> record.check?'':'schedule-table-check'"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps:record=>({props:{disabled:!record.course_class_id}})}"
        :columns="columns" :dataSource="list" :scroll="{ x: 1800 }">
        <template slot="index" slot-scope="text, record , index">
          <!-- <span v-if="index+1 == list.length">合计</span> -->
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="classDate" slot-scope="text, record">
          
          <a @click="showStudentsModal(record)" style="display:flex">
            <div style="margin-right:5px;line-height:19px" v-if="record.class_start">{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}
              <!-- <img v-if="record.is_demo" style="width:18px" src="@/assets/demo.png"> -->
              <!-- <PayCircleOutlined /> -->
              <!-- <a-icon type="" /> -->

              <a-tooltip>
                <template slot="title">
                  可预约
                </template>
                <a-icon type="clock-circle" v-if="record.class_mode==1" />
              </a-tooltip>

              <a-tooltip>
                <template slot="title">
                  试听课
                </template>
                <a-icon type="pay-circle" v-if="record.is_demo" />
              </a-tooltip>

              <a-tooltip>
                <template slot="title">
                  上课回放
                </template>
                <a-icon type="play-circle" v-if="record.is_live_record" />
              </a-tooltip>
              
            </div>
          </a>
        </template>

        <template slot="class_id" slot-scope="class_name, record">
        <a-tooltip>
          <template slot="title">
            {{ class_name }}
          </template>
          <a @click="showDetailModal(record,'1')">{{ class_name }}</a>
        </a-tooltip>
        </template>

         <template slot="class_status" slot-scope="text, record">
          <a-tag color="green" v-if="text=='已上课'">
              {{ text }}
          </a-tag>
          <a-tag color="orange" v-if="text=='待上课'">
              {{ text }}
          </a-tag>
        </template>

        <template slot="reserve_count" slot-scope="text, record">
          <a @click="showStudentsModal(record)">{{ record.reserve_count }} / {{ record.table_count }}</a>
        </template>

        <template slot="signin_count" slot-scope="text, record">
          <a @click="showStudentsModal(record)">{{ record.signin_count }}</a>
        </template>

        <template slot="cancel_count" slot-scope="text, record">
          <a @click="showStudentsModal(record)">{{ record.cancel_count }}</a>
        </template>

        <template slot="is_summary" slot-scope="text, record">
            <svg @click="showContentModal(record)" style="width:20px;height: 20px;vertical-align: middle;cursor: pointer;" aria-hidden="true">
                <use  v-show="record.is_summary == true" xlink:href="#icon-beike-active"></use>
                <use v-show="record.is_summary == false" xlink:href="#icon-beike"></use>
            </svg>
        </template>

        <template slot="action" slot-scope="text,record">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item @click="showStudentsModal(record)" key="2">
                学员签到
              </a-menu-item>
              <a-menu-item @click="cleanCourse(record)" key="5">
                撤销签到
              </a-menu-item>
              <a-menu-item key="3" @click="deleteCourse(record)">
                取消排课
              </a-menu-item>
              <a-menu-item @click="showTeacherModal(record)" key="4">
                代课老师
              </a-menu-item>
              <a-menu-item @click="showContentModal(record)" key="1">
                上课内容
              </a-menu-item>
              <a-menu-item @click="showContentModal(record)" key="8">
                课后反馈
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
    </div>
    <a-modal title="取消课程" v-model="cancelVisible" :confirmLoading="confirmLoading" @ok="hideCancelModal" okText="确认" cancelText="取消">
      <a-textarea placeholder="请输入取消原因" v-model="reason" :rows="4"/>
      <a-checkbox :checked="send_notice" @change="handleCheck">微信公众号发送取消上课信息。</a-checkbox>
    </a-modal>
    <substituteModal v-if="teacherVisible" :item="modalData"/>

    <pkModal v-if="isPkModal" :studio_list="studios"/>
    
    <timeModal v-if="isNextDay" :item="selectedRowKeys"/>

    <studentsModal v-if="studentsVisible" :item="modalData"/>
    <contentModal v-if="isContentModal" :item="modalData"/>
    <detailModal v-if="detailVisible" :item="modalData"/>
    <adjustModal v-if="isAdjustModal" :item="selectedRowKeys" :teachers="teachers" />
  </div>
</template>

<script>
import moment from 'moment'
import C_ITEMS from '@/utils/items'
import pkModal from './pkModal'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import contentModal from './contentModal'
import studentsModal from '@/views/classes/bjgl/detail/bjpk/studentModal'
import adjustModal from '../../absence/kcpq/adjustModal'
import timeModal from '../../absence/kcpq/timeModal'
import substituteModal from './../../../classes/bjgl/detail/bjpk/substituteModal'
import detailModal from '@/views/classes/bjgl/detail/index'

const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '', width: '35px', dataIndex: 'is_summary', key: 'is_summary',scopedSlots: { customRender: 'is_summary' }, fixed: 'left'},
  { title: '上课时间', width:'250px', dataIndex: 'class_start', key: '1', scopedSlots: { customRender: 'classDate' } , fixed: 'left' ,sorter:()=>{}},
  { title: '班级名称', dataIndex: 'class_id', key: 'class_id', scopedSlots: { customRender: 'class_id' } ,sorter:()=>{},ellipsis:true},
  { title: '上课校区', dataIndex: 'studio_id', key: 'studio_id' ,sorter:()=>{},ellipsis:true},
  { title: '授课老师', dataIndex: 'teacher_id', key: '2' ,sorter:()=>{},ellipsis:true,align:"center"},
  { title: '助教老师', dataIndex: 'teacher_assistant', key: 'teacher_assistant' ,ellipsis:true},
  { title: '上课教室', dataIndex: 'room_id', key: 'room_id',sorter:()=>{},ellipsis:true,align:"center"},
  { title: '课程名称', width: '160px', dataIndex: 'course_id',sorter:()=>{},ellipsis:true,align:"center"},
  { title: '排课', dataIndex: 'reserve_count',sorter:()=>{},width:'80px', key: '4', scopedSlots: { customRender: 'reserve_count' } ,align:"center"},
  { title: '到课', dataIndex: 'signin_count',sorter:()=>{},width:'80px', key: '5', scopedSlots: { customRender: 'signin_count' } ,align:"center"},
  { title: '请假', dataIndex: 'cancel_count',sorter:()=>{},width:'80px',key: 'cancel_count', scopedSlots: { customRender: 'cancel_count' } ,align:"center"},
  { title: '单课消耗', dataIndex: 'class_costs',width:'80px', key: '6' ,align:"center"},
  { title: '全员扣课', dataIndex: 'total_class_costs',width:'80px',  key: 'total_class_costs' ,align:"center"},
  { title: '扣课金额', dataIndex: 'total_class_costs_price',width:'80px',  key: 'total_class_costs_price' ,align:"center"},
  { title: '状态', dataIndex: 'class_status',scopedSlots: { customRender: 'class_status' }, key: '9' ,align:"center",sorter:()=>{}},
  { title: '排课人员', dataIndex: 'created_by', key: 'created_by' ,align:"center"},
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]
    export default {
        name:'listMode',
        data() {
            return {
                appid:'',
                loading: false,
                syncLoading: false,
                cancelVisible: false,
                exportLoading: false,
                studentsVisible: false,
                isPkModal: false,
                isAdjustModal: false,
                isNextDay: false,
                send_notice: true,
                confirmLoading: false,
                isContentModal: false,
                teacherVisible: false,
                detailVisible:false,
                columns,
                list:[],
                studios: [],
                rooms: [],
                curItem:{},
                reason:'',
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0,
                    },
                    "sort": ''
                },
                teachers: [],
                classCategorys:[],
                classModes: C_ITEMS.classModes,
                customer_optionList:[
                    {label:'等于',value:'='},
                    {label:'小于',value:'<'},
                    {label:'小于等于',value:'<='},
                    {label:'大于',value:'>'},
                    {label:'大于等于',value:'>='},
                ],
                customer_optionValue:'=',
                customer_number:'',

                book_optionList:[
                    {label:'等于',value:'='},
                    {label:'小于',value:'<'},
                    {label:'小于等于',value:'<='},
                    {label:'大于',value:'>'},
                    {label:'大于等于',value:'>='},
                ],
                book_optionValue:'=',
                book_number:'',
            }
        },
        mixins: [ tableMixins , ranges ],
        components: {
          pkModal,
          studentsModal,
          adjustModal,
          timeModal,
          detailModal,
          contentModal,
          substituteModal
        },
        computed: {
          realname(){
            return this.$store.getters.realname
          },
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.appid = this.$ls.get('appid')
            this.paramsData.start_date = moment().format('YYYY-MM-DD')
            this.paramsData.end_date = moment().add(1,'week').format('YYYY-MM-DD')
            if(this.$route.query.start_date){
                this.rangesValue = [moment(this.$route.query.start_date,'YYYY-MM-DD'),moment(this.$route.query.end_date,'YYYY-MM-DD')]
                this.paramsData.start_date = moment(this.$route.query.start_date).format('YYYY-MM-DD')
                this.paramsData.end_date = moment(this.$route.query.end_date).format('YYYY-MM-DD')
            }else{
                this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            }
            this.getRoom()
            this.getTeacher()
            this.getStudioList()
            this.getClassCategory()
        },
        methods: {
            showPkModal(){
              this.isPkModal = true
            },
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                this.searchParams.search.start_date = this.$route.query.start_date || this.paramsData.start_date || moment().format('YYYY-MM-DD')
                this.searchParams.search.end_date = this.$route.query.end_date || this.paramsData.end_date || moment().add(1,'week').format('YYYY-MM-DD')
                let res = await this.$store.dispatch('scheduleIndexAction', this.searchParams)
                this.list = res.items
                // this.list.push({
                //   'course_class_id':'',
                //   'check':true,
                //   'reserve_count':res._total.reserve_count,
                //   'signin_count':res._total.signin_count,
                //   'cancel_count':res._total.cancel_count,
                //   // 'total_class_costs':res._total.total_class_costs,
                //   // 'total_class_costs_price':res._total.total_class_costs_price,
                // })
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudioList() {
                let res = await this.$store.dispatch('searchBelongStudioAction', { })
                this.studios = res.data
            },
            async getRoom(obj) {
                let res = await this.$store.dispatch('searchStudioRoomAction', {params:obj})
                this.rooms = res.data
            },
            async getTeacher(obj) {
              let res = await this.$store.dispatch('searchTeacherAction', {params:obj})
              this.teachers = res.data
            },
            async getClassCategory(obj) {
                let res = await this.$store.dispatch('searchCourseTypeAction', {params:obj})
                this.classCategorys = res.data
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {search:this.searchParams.search,sort:this.searchParams.sort}
                exportData.export_type = 'list'
                let res = await this.$store.dispatch('schedulecourseExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `我的课表.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
            showStudentsModal(item) {
              this.modalData = item
              this.studentsVisible = true
            },
            hideStudentModal(type) {
              this.studentsVisible = false
            },
            showContentModal(item){
              this.modalData = item
              this.isContentModal = true
            },
            hideContentModal(val){
              if(val){
                this.getList()
              }
              this.isContentModal = false
            },
            showDetailModal(item,num) {
              this.modalData = item.class
              this.modalData.activeIndex = num
              this.detailVisible = true
            },
            hideDetailModal() {
              this.detailVisible = false
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            handleSearch(val,name){
              this[name]({q:val})
            },
            onChangeBook(){
                if(this.book_optionValue && this.book_number){
                    this.searchParams.search.reserve_count = `${this.book_optionValue} ${this.book_number}`
                }else{
                    this.searchParams.search.reserve_count = undefined
                }
                console.log(this.searchParams.search)
            },
            onChangeCostWarn(){
                if(this.customer_optionValue && this.customer_number){
                    this.searchParams.search.times = `${this.customer_optionValue} ${this.customer_number}`
                }else{
                    this.searchParams.search.times = undefined
                }
                console.log(this.searchParams.search)
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
            deleteCourse(item) {
              this.cancelVisible = true
              this.curItem = item
            },
            async hideCancelModal() {
              this.confirmLoading = true
              let selectedRowKeys = this.selectedRowKeys.length>0?this.selectedRowKeys:[this.curItem.course_class_id]
              await this.$store.dispatch('courseScheduleCancelCourseAction', { selection: selectedRowKeys, reason: this.reason, send_notice:this.send_notice?1:0})
              .then(res=>{
                this.reason = ''
                this.selectedRowKeys = []
                this.$message.success('操作成功!')
                this.cancelVisible = false
                this.confirmLoading = false
                this.send_notice = false
                this.getList()
              })
            },
            toCancel(){
              this.cancelVisible = true
            },
            showAdjustModal(){
              this.isAdjustModal = true
            },
            hideAdjustModal(val){
              if(val === 1){
                this.selectedRowKeys = []
                this.$message.success('操作成功!')
                this.getList()
              }
              this.isAdjustModal = false
            },
            showTimeModal(){
              this.isNextDay = true
            },
            hideTimeModal(val){
              if(val === 1){
                this.selectedRowKeys = []
                this.$message.success('操作成功!')
                this.getList()
              }
              this.isNextDay = false
            },
            hidePkModal(){
              this.isPkModal = false
            },
            showTeacherModal(item) {
              this.modalData = item
              this.teacherVisible = true
            },
            hideSubModal(type) {
              if (type === 1) {
                // this.$message.success('操作成功!')
                this.getList()
              }
              this.teacherVisible = false
            },
            handleTypeButChange(e,name){
              if(this.searchParams.search[name] == e){
                this.searchParams.search[name] = undefined
              }else{
                this.searchParams.search[name] = e
              }
              this.getList()
            },
            async toSync(){
              this.syncLoading = true
              await this.selectedRowKeys.forEach(item=>{
                this.courseScheduleXgjCheck(item)
              })
              this.syncLoading = false
              this.selectedRowKeys = []
            },
            async courseScheduleXgjCheck(id){
              await this.$store.dispatch('courseScheduleXgjCheckAction',{course_class_id:id})
              .then(res=>{
                if(!res.data.check){
                  this.list.forEach(item=>{
                    if(item.course_class_id == id){
                      item.check = false
                    }
                  })
                }
              })
            },
            cleanCourse(item){
              let that = this
              let selection = item?[item.course_class_id]:this.selectedRowKeys
              this.$confirm({
                title: `您确定撤销签到？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    await that.$store.dispatch('courseScheduleCleanCheckinAction', { selection } )
                    .then(res=>{
                      that.$message.success('操作成功！！')
                      that.selectedRowKeys = []
                      that.getList()
                      resolve(res)
                    })
                    .catch(err=>{
                      resolve(err)
                    })
                  }).catch(error => console.log(error))
                }
              })
            },
            handleCheck(){
              this.send_notice = !this.send_notice
            }

        },
    }
</script>

<style lang="scss">
  .schedule-table-check{
    background: rgba(245,34,34,0.2);
  }
</style>