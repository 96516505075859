<template>
    <div>
        <div class="clearfix table-tools">
      <div class="buttons">
          <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
                    </a-form-item>
                </a-form>
            </div>
      </div>
      <div class="search">
          <a-form layout='inline'>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>所属校区</span>
                </template>
                    <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch allowClear placeholder="请选择校区" :filterOption="filterOption" style="width:210px">
                        <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
                    </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchParams.search.course_cate_id" placeholder="请选择课程科目" showSearch @search="e=>handleSearch(e,'getClassCategory')" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item v-if="searchParams.search.is_sub == 1" class="block-line" label="授课老师" >
                        <a-select allowClear v-model="searchParams.search.teacher_id" showSearch @search="e=>handleSearch(e,'getTeacher')" :filterOption="filterOption" placeholder="请选择">
                          <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="课程模式" >
                        <a-select allowClear v-model="searchParams.search.class_mode" placeholder="请选择">
                          <a-select-option v-for="(d, index) of classModes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="上课教室" >
                        <a-select v-model="searchParams.search.room_id" showSearch @search="e=>handleSearch(e,'getRoom')" allowClear placeholder="请选择上课教室" :filterOption="filterOption">
                            <a-select-option v-for="(item, index) of rooms" :key="index" :value="item.room_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
      </div>
    </div>
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <full-calendar ref="calendar" :events="events" :config="config"></full-calendar>
        </div>
        <studentsModal v-if="studentsVisible" :item="modalData"/>
    </div>
</template>

<script>
    import moment from 'moment'
    import C_ITEMS from '@/utils/items'
    import studentsModal from '@/views/classes/bjgl/detail/bjpk/studentModal'
    export default {
        name:'viewMode',
        data() {
            return {
                loading:false,
                visible:false,
                exportLoading:false,
                studentsVisible:false,
                modalData:{},
                studios: [],
                rooms: [],
                events:[],
                config:{
                    firstDay:1,
                    locale:'zh-cn',
                    defaultView: 'basicWeek',
                    height:'300px',
                    header:{
                        left:'',
                        center:'title',
                        right:'prev,today,next',
                    },
                    /* 设置按钮文字 */
                    buttonText:{
                        today:'本周',
                    },
                    editable:false,
                    eventClick: this.showStudentsModal,
                    viewRender: this.viewRenderAction,
                },
                searchParams: {
                    "search": {
                        is_sub:0,
                        start_date:moment().startOf('week').format('YYYY-MM-DD'),
                        end_date:moment().endOf('week').format('YYYY-MM-DD')
                    },
                    unlimit:1
                },
                teachers: [],
                classCategorys:[],
                classModes: C_ITEMS.classModes,
            }
        },
        provide() {
            return {
            parent: this
            }
        },
        components: {
            studentsModal,
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.getRoom()
            this.getTeacher()
            this.getStudioList()
            this.getClassCategory()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                let res = await this.$store.dispatch('scheduleIndexAction', this.searchParams)
                let events = []
                res.items.forEach(item=>{
                    let obj = {
                        id:item.course_class_id,
                        title:`
                            课程名称: ${item.course_id}
                            上课时间: ${item.class_time}
                            上课老师: ${item.teacher_id}
                            上课校区: ${item.studio_id}
                            上课教室: ${item.room_name}
                            上课数据: ${item.signin_count} / ${item.reserve_count} / ${item.table_count}`,
                        start:moment(item.class_start).format('YYYY/MM/DD HH:mm'),
                        end:moment(item.class_start).format('YYYY/MM/DD HH:mm'),
                        color:item.course_color,
                        details:item,

                    }
                    events.push(obj)
                })
                this.events = events
                this.loading = false
            },
            async getTeacher(obj) {
              let res = await this.$store.dispatch('searchTeacherAction', {params:obj})
              this.teachers = res.data
            },
            async getStudioList() {
                let res = await this.$store.dispatch('searchBelongStudioAction', { })
                this.studios = res.data
            },
            async getRoom(obj) {
                let res = await this.$store.dispatch('searchStudioRoomAction', {params:obj})
                this.rooms = res.data
            },
            async getClassCategory(obj) {
                let res = await this.$store.dispatch('searchCourseTypeAction', {params:obj})
                this.classCategorys = res.data
            },
            viewRenderAction(e) {
                this.searchParams.search.start_date = moment(e.start).format('YYYY-MM-DD')
                this.searchParams.search.end_date = moment(e.end).format('YYYY-MM-DD')
                this.getList()
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            handleSearch(val,name){
              this[name]({q:val})
            },
            searchList(e){
                e?e.preventDefault():''
                this.getList()
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {search:this.searchParams.search,visual:1}
                exportData.export_type = 'calendar'
                let res = await this.$store.dispatch('schedulecourseExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `我的课表.${moment().format('YYYY.MM.DD')}.xls`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
            showStudentsModal(item) {
              this.modalData = item.details
              this.studentsVisible = true
            },
            hideStudentModal(type) {
              this.studentsVisible = false
            },
            onChange(){
              this.getList()
            },
            closeSearch() {
                this.visible = false
            },
            reset() {
                this.searchParams.search = {}
            },
        },
    }
</script>

<style lang="scss">

</style>