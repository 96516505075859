<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>我的课表</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">列表模式</span>
          <listMode v-if="activeKey === '1'" />
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">视图模式</span>
          <viewMode v-if="activeKey === '2'" />
        </a-tab-pane>
        <!-- <a-tab-pane key="3">
          <span slot="tab">Scheduling模式</span>
          <schedulingMode v-if="activeKey === '3'" />
        </a-tab-pane> -->
        <!-- <a-tab-pane key="3">
          <span slot="tab">视频</span>
          <agoraRtc v-if="activeKey === '3'" />
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
    import agoraRtc from './agoraRtc/index'
    // import schedulingMode from './schedulingMode/index'
    import listMode from './listMode/index'
    import viewMode from './viewMode/index'
    import classMode from './classMode/index'
    export default {
        name:'mySchedule',
        data() {
            return {
                activeKey: '1',
            }
        },
        components:{
            listMode,
            viewMode,
            classMode,
            agoraRtc,
            // schedulingMode
        },
        methods:{
            changeTab(key) {
                this.activeKey = key
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>