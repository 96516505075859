<template>
    <div>
        <a-button @click="toJoin" type="primary">加入频道</a-button>
        <a-button @click="toLeave" type="primary">离开频道</a-button>
        <agora 
            ref="agoraRef"
            :autoStart="false"
            :token="myToken" 
            :channel="channel"
            appid="532e4ad759d24fc9be5f6c074ced1b55" 
            :clientConfig="{ mode: 'live', role: role }"
            >
            <agora-audio-sender />
            <agora-audio-receiver />
            <agora-video-receiver />
            <agora-video-sender />
        </agora>
    </div>
</template>

<script>
    export default {
        name:'agoraRtc',
        data() {
            return {
                channel: '123',
                myToken:'006532e4ad759d24fc9be5f6c074ced1b55IAAwj4WI1JCn+Qj39sDsz98DiJAQ3NzbZfU1Q0i6PJ9pC9JjSIgAAAAAEAARnS3H9rnKYAEAAQDhucpg',
                role:'host'
            }
        },
        created () {

        },
        methods: {
            toJoin() {
                this.$refs.agoraRef.start()
            },
            toLeave() {
                this.$refs.agoraRef.leave()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>